import React from 'react';
import "./src/assets/css/default.css";
import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`

`;

export const wrapRootElement = ({ element }) => (
  <div>
    <GlobalStyles />
    {element}
  </div>
);
